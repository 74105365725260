<template>
  <div>
    <p class="title">Clientes</p>
    <hr />
    <DataGridFactory
      :dataSource="dataSource"
      :AddButtonFunction="AddButton"
      :EditButtonFunction="EditButton"
      :ButtonRefreshToolbarFunction="RefreshButton"
      :OnRowDoubleClick="GetMoviments"
    >
      <template v-slot:button>
        <DxButton
          hint="Exibir Movimentação da Empresa"
          icon="mdi mdi-account-cash mdi-18px"
          :on-click="GetMoviments"
        />
      </template>

      <template v-slot:column>
        <DxColumn
          alignment="left"
          data-field="nomeFantasia"
          sort-order="asc"
          caption="Nome"
        />

        <DxColumn
          data-field="cnpjCpf"
          caption="CNPJ/CPF"
          alignment="right"
          :customize-text="(cell) => {
            return Validators.formataCpfCnpj(cell.value)
          }"
        />

        <DxColumn data-field="email" alignment="center" />

        <DxColumn
          data-field="telefone"
          alignment="right"
          :customize-text="
          (cell) => {
            return Validators.formataNumeroTelefone(cell.value);
          }"
        />

        <DxColumn data-field="contabilista" alignment="left" />

        <DxColumn
          data-field="tamanhoArquivos"
          caption="Armaz."
          alignment="right"
          :customize-text="(cell) => {
            return Validators.formatarCampoArmazenamento(cell.value)
          }"
          :visible="auth.getIsAdmin"
        />

        <DxColumn data-field="ativo" />
      </template>
    </DataGridFactory>
    <DxLoadPanel :visible="state.isLoading" :show-pane="true" :shading="true" shading-color="rgba(255, 255, 255, 0.2)" />
  </div>
</template>
<script lang="ts" src="./index.ts"></script>
<style>
.dx-state-hover {
  cursor: pointer;
}
</style>
