import { authStore } from './../../store/modules/auth/index';
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, watchEffect } from "vue";
import { DxColumn, DxButton, } from 'devextreme-vue/ui/data-grid';
import { clienteStore } from "../../store/modules/clientes";
import eventBus from '../../helpers/event-bus';
import router from '../../router';
import Validators from '../../helpers/validators-input';
import DataSource from 'devextreme/data/data_source';
import DataGridFactory from '../../components/datagrid-factory/index.vue'
import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';

export default defineComponent({
  name: 'Clientes',
  setup() {
    onMounted(() => {
      dataSourceEvent.listen(ReloadDataSource)
    })

    onBeforeUnmount(() => {
      dataSourceEvent.off(ReloadDataSource)
    })

    const state = reactive({ contabilistaId: 0, isLoading: false })
    const popupEvent = eventBus.usePopup()
    const dataSourceEvent = eventBus.useDataSource();
    const store = clienteStore()
    const auth = authStore();
    const RefreshButton = computed(() => {
      return auth.getIsAdmin ?
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            hint: 'Carregar Todos os Clientes',
            onClick: () => {
              router.replace({ params: { id: '' } })
              state.contabilistaId = 0;
              ReloadDataSource()
            }
          }
        }
        : null
    })
    const dataSource = new DataSource({
      key: 'id',
      load: () => TypeLoad()
    })

    watchEffect(() => {
      if (router.currentRoute.value.params.id) {
        state.contabilistaId = (+router.currentRoute.value.params.id);
        ReloadDataSource()
      }
      ReloadDataSource()
    })

    function TypeLoad(): any {
      if (state.contabilistaId !== 0) {
        return store.LOAD_CLIENTES_BY_CONTABILISTA_ID(state.contabilistaId)
      } 
      if (auth.currentUser.tipo && auth.currentUser.tipo !== 0) {
        return store.LOAD(auth.currentUser.contabilistaId, auth.currentUser.tipo)
      } 
    }

    async function AddButton() {
      await popupEvent.open({
        component: 'FormCliente',
        title: 'Adicionar Novo Cliente',
        props: {
          id: 0,
          contabilistaID: null,
          razaoSocial: '',
          nomeFantasia: '',
          email: '',
          cnpjCpf: null,
          telefone: '',
          ativo: true,
          isReadonly: false
        },
        size: { width: 600, height: 440 }
      })
    }

    async function EditButton(e: any) {
      store.$reset()
      const rowKey = e.row.key;
      if (rowKey) await store.GET_CLIENTE_BY_ID(rowKey)
      await popupEvent.open({
        component: 'FormCliente',
        title: `Dados do Cliente: ${store.cliente.nomeFantasia}`,
        props: Object.assign(store.cliente, { isReadonly: true }),
        size: { width: 600, height: 440 }
      })
    }

    function GetMoviments(e: any) {
      state.isLoading = true;
      setTimeout(() => {
        const rowData = e.data ? e.data : e.row.data;
        store.$reset()
        store.SET_CLIENTE_FOR_MOVIMENTS(rowData);
        state.isLoading = false;
        router.push({ path: '/movimentacoes' })
      }, 1000)
    }

    function ReloadDataSource() {
      dataSource.reload()
    }

    return {
      AddButton,
      EditButton,
      GetMoviments,
      RefreshButton,
      dataSource,
      auth,
      state,
      Validators
    }
  },
  components: {
    DxColumn,
    DxButton,
    DataGridFactory,
    DxLoadPanel
  }
})